import React, { useState } from "react";
import { motion } from "framer-motion";
const WordCounter = () => {
    const [text, setText] = useState("");
    const [wordCount, setWordCount] = useState(0);
    const [charCount, setCharCount] = useState(0);
    const [copyMessage, setCopyMessage] = useState("");
    function handleWordCount(event) {
        const newText = event.target.value;
        setText(newText);
        const words = newText
            .trim()
            .split(/\s+/)
            .filter((word) => word.length > 0);
        setWordCount(words.length);
        setCharCount(newText.replace(/\s+/g, "").length);
    }
    function handleCopyToClipboard() {
        navigator.clipboard
            .writeText(text)
            .then(() => {
            setCopyMessage("Copied!");
            setTimeout(() => setCopyMessage(""), 2000);
        })
            .catch(() => {
            setCopyMessage("Failed to copy");
        });
    }
    return (React.createElement("div", null,
        React.createElement("div", { className: "flex flex-col sm:flex-row items-center space-x-4 space-y-4 sm:space-y-0 mb-4" },
            React.createElement("div", { className: "flex items-center space-x-4" },
                React.createElement("p", { className: "font-bold" }, "Word Count: "),
                React.createElement("span", null, wordCount),
                React.createElement("p", { className: "font-bold" }, "Character Count: "),
                React.createElement("span", null, charCount)),
            React.createElement("div", { className: "copyButton flex items-center space-x-4" },
                React.createElement(motion.button, { whileHover: { scale: 1.1 }, whileTap: { scale: 0.9 }, onClick: handleCopyToClipboard, className: "inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10" }, "Copy to clipboard"),
                React.createElement("span", null, copyMessage))),
        React.createElement("textarea", { className: "h-64 w-full border border-gray-300 rounded-lg shadow p-4 text-gray-900", onChange: handleWordCount })));
};
export default WordCounter;
