import React, { createContext, useState, useContext } from "react";
// Create a UserContext with default value
const UserContext = createContext(undefined);
// Custom hook to access the UserContext
export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
// UserProvider component to wrap the app and provide the user state
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    // Function to update the user state
    const loginUser = (userInfo) => {
        setUser(userInfo);
    };
    // Function to clear the user state on logout
    const logoutUser = () => {
        setUser(null);
    };
    return (React.createElement(UserContext.Provider, { value: { user, loginUser, logoutUser } }, children));
};
