import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, useLocation, } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AnimatePresence } from "framer-motion";
import { useUser, UserProvider } from "./contexts/UserContext";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import "./index.css";
import WordCounter from "./components/WordCounter";
const App = () => {
    const { user } = useUser();
    const location = useLocation();
    const routeNames = ["Word Counter"];
    const formattedRoutes = routeNames.map((route) => route.toLowerCase().replace(/\s+/g, "-"));
    return (React.createElement(AnimatePresence, { mode: "wait" },
        React.createElement(Routes, { location: location, key: location.pathname },
            React.createElement(Route, { path: "/", element: React.createElement(Login, null) }),
            user ? (React.createElement(React.Fragment, null,
                React.createElement(Route, { path: "/home", element: React.createElement(Home, { routes: routeNames }) },
                    React.createElement(Route, { key: formattedRoutes[0], path: formattedRoutes[0], element: React.createElement(WordCounter, null) })),
                React.createElement(Route, { path: "*", element: React.createElement(NotFound, null) }))) : (
            // If not authenticated, redirect to Login
            React.createElement(Route, { path: "*", element: React.createElement(Login, null) })))));
};
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
root.render(React.createElement(GoogleOAuthProvider, { clientId: "931086155425-n0a5ltsajma5vk1ck3gbtstfjplsrhhn.apps.googleusercontent.com" },
    React.createElement(UserProvider, null,
        React.createElement(Router, null,
            React.createElement(App, null)))));
