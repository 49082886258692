// src/pages/NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
    const navigate = useNavigate(); // Use the useNavigate hook to get the navigate function
    const handleGoHome = () => {
        navigate("/"); // Navigate to the home page
    };
    return (React.createElement("div", { className: "not-found flex flex-col justify-center items-center space-y-6 text-gray-900" },
        React.createElement("img", { src: "images/kuUglyBird.jpeg", onClick: handleGoHome, className: "cursor-pointer" }),
        React.createElement("h1", { className: "font-bold text-xl" }, "404 - Page Not Found"),
        React.createElement("p", null, "The page you are looking for does not exist."),
        React.createElement("button", { onClick: handleGoHome, className: "home-button bg-blue-200 rounded-lg text-blue-500 px-4 py-2 border border-blue-500" }, "Go to Home page")));
};
export default NotFound;
