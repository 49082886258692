import React, { Children, useState } from "react";
import { motion } from "framer-motion";
import { Outlet, useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
function HomePageRoutes({ children, routes }) {
    const navigate = useNavigate(); // Use the useNavigate hook to get the navigate function
    const handleNavigate = (route) => {
        navigate(route); // Navigate to the specified route
    };
    return (React.createElement("div", { className: "flex justify-center items-center space-x-4" }, Children.map(children, (child, index) => (React.createElement(motion.button, { whileHover: { scale: 1.1 }, whileTap: { scale: 0.9 }, key: index, className: "px-4 py-2 bg-blue-200 text-blue-500 border border-blue-500 rounded-lg", onClick: () => handleNavigate(routes[index]) }, child)))));
}
const Home = ({ routes }) => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const { user } = useUser();
    const handleNavigate = (route) => {
        navigate(route);
    };
    const formattedRoutes = routes.map((route) => route.toLowerCase().replace(/\s+/g, "-"));
    return (React.createElement("div", { className: "bg-gray-900 min-h-screen text-white flex flex-col items-center w-full" },
        React.createElement("div", { onClick: () => handleNavigate("/"), onMouseEnter: () => setIsHovered(true), onMouseLeave: () => setIsHovered(false), className: "flex flex-col items-center cursor-pointer" },
            React.createElement("h1", { className: "text-xl font-bold mt-4" },
                "Welcome ",
                user ? user.name : "",
                "!"),
            React.createElement(motion.div, { initial: { opacity: 0, y: "100%" }, animate: { opacity: isHovered ? 1 : 0, y: isHovered ? 0 : "100%" }, viewport: { once: true }, className: "text-gray-600 font-sm" }, "Click to navigate home")),
        React.createElement("p", { className: "pt-4" }, "Select a component below to begin!"),
        React.createElement("main", { className: "w-full" },
            React.createElement("div", { className: "p-4" },
                React.createElement(HomePageRoutes, { routes: formattedRoutes }, routes.map((route) => (React.createElement("p", null, route))))),
            React.createElement("div", { className: "p-4" },
                React.createElement(Outlet, null)))));
};
export default Home;
