import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { jwtDecode } from "../utils/jwtUtils";
const Login = () => {
    const navigate = useNavigate();
    const { loginUser } = useUser(); // Access the loginUser function from the context
    function handleSuccess(response) {
        // Extract user information from the response
        const decoded = jwtDecode(response.credential);
        const userInfo = {
            name: decoded.name || "Unknown",
            email: decoded.email || "Unknown",
        };
        console.log(response);
        // Update the user context
        loginUser(userInfo);
        // Navigate to the home page
        navigate("/home");
    }
    return (React.createElement("div", { className: "flex flex-col space-y-4 items-center justify-center bg-gray-900 text-white min-h-screen" },
        React.createElement("h2", { className: "text-xl font-bold" }, "Login to Rachelle's App"),
        React.createElement(GoogleLogin, { onSuccess: handleSuccess })));
};
export default Login;
